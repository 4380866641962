export const FirebaseConfig = {
  apiKey: "AIzaSyBgxPj6dGvyPx0lbENeaBvwLWU1nzjxuiI",
  authDomain: "beba-mombasa.firebaseapp.com",
  databaseURL: "https://beba-mombasa-default-rtdb.firebaseio.com",
  projectId: "beba-mombasa",
  storageBucket: "beba-mombasa.appspot.com",
  messagingSenderId: "873289141193",
  appId: "1:873289141193:web:c8e45f0db95d5fd236f618",
  measurementId: "G-8FB93TZV0T",
};
