export default [
  {
    title: "Book Online ",
    description: "Your ride is a just a one click of a button away",
    urls: "https://images.pond5.com/hands-steering-wheel-car-night-footage-131883858_iconl.jpeg",
  },
  {
    title: "Become a Driver",
    description: "Earning with your car with BebaFleet is Easy",
    urls: "https://images.pond5.com/highway-night-road-car-driving-087629868_prevstill.jpeg",
  },
  {
    title: "Package/Percel Delivery",
    description:
      "Get your luggages/loads/percel delivered by a reliable company",
    urls: "https://media.istockphoto.com/id/960357234/photo/car-moving-on-highway-at-night.jpg?s=612x612&w=0&k=20&c=-NOiFUk_J-bQLKEwjorjWhTwGVft1WBKujyZ6P1eAE0=",
  },
];
